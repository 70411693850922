import React from 'react';
import './style.scss';
import Wrapper from '../../templates/Wrapper/Wrapper';
import SEO from '../../components/seo';
import FeaturedImage from "../../components/FeaturedImage/FeaturedImage";
import EmailSignUp from '../../components/Forms/EmailSignUp/EmailSignUp';
import ContactForm from '../../components/Forms/ContactForm/ContactForm'; // This is here for now for testing..
import DonationsForm from '../../components/Forms/DonationsForm/DonationsForm';

const BasicPage = ({pageContext}) => {

    const decodedTitle = decodeURIComponent(pageContext.title);
    const emailSignUp = pageContext.acf.email_sign_up ? <EmailSignUp groupid={pageContext.acf.groupid} cta_text={pageContext.acf.cta_text} /> : null;
    const contactform = pageContext.slug === "contact" ? <ContactForm /> : null;
    const donationsForm = pageContext.acf.include_donation_form ? <DonationsForm campaign={pageContext.acf.campaign_code} domain="www.flipcause.com" /> : null;


    return (
        <Wrapper pageCurrent={ `basic ${pageContext.title.toLowerCase()}` }>
            <SEO title={decodedTitle} />

            <div className="blockwrap menu-spacer">
                <FeaturedImage pageContext={pageContext} /> 
            </div>

            <div className="blockwrap">
                
                <div className="container base-styles">
                    <div className="content">
                        <div className="inner-heading-wrap">
                            <h1 className="animate-underline" dangerouslySetInnerHTML={{__html: pageContext.title}}></h1>
                        </div>
                        <div className="e" dangerouslySetInnerHTML={{__html: pageContext.content}} />
                       
                    </div> 
                </div>
            
            </div>
            {contactform}
            {donationsForm}
            {emailSignUp}
               
        </Wrapper>
    )

}
export default BasicPage;

