import React from 'react';
import './style.scss';
import Columns from '../../Columns/Columns';
import InputWrap from '../InputWrap/InputWrap';
import Iff from '../../Iff';


class ContactForm extends React.Component {
    state = {
        name: null,
        phone: null,
        email: null,
        message: null,
        sent: false,
        error: false,
        error_message: null
    }

    _handleChange = (e) => {
        this.setState({
            [`${e.target.name}`]: e.target.value,
        });
    }

    _handleSubmit = (e) => {
        e.preventDefault();
        if (!this.state.name || this.state.name.trim().length < 2) {
            this.setState({error: "NAME"});
        } else if (!this.state.email || this.state.email.trim().length < 2 || !this.state.email.includes('@')) {
            this.setState({error: "email"});
        } else if (!this.state.message || this.state.message.trim().length < 2) {
            this.setState({error: "message"});
        } else
            this.mail();
    }

    mail = async () => {
        const url = 'https://'+process.env.GATSBY_WORDPRESS_LOCATION+'/wp-json/contact/v1/send'; // Pull the host from the env eventually

        let headers = {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
			body: JSON.stringify({
                name: this.state.name,
                phone: this.state.phone,
                email: this.state.email,
                message: this.state.message
            })
		};
		let response = await fetch(url, headers); 
		if (response.status === 200)  {
            this.setState({sent: true, error: null});
        } else {
            this.setState({error: true, error_message: "There was an error sending your email.  Please try again later."});
        }
	}

    render() {
        return (
            <div className="blockwrap">
                <div className="base-styles contact-form">
                    <Columns className="content">
                        <Iff condition={this.state.sent}>
                            <div className="success">
                                <h1 className="animate-underline">Thank you! <br />We will be in touch shortly.</h1>
                            </div>
                        </Iff>
                        <Iff condition={!this.state.sent}>
                            <div className="description">
                                <h1 className="animate-underline">Send us a message</h1>
                                <div className="text">
                                    <Iff condition={this.state.error === true}>
                                        <p className="error">{this.state.error_message}</p>
                                    </Iff>
                                </div>
                            </div>
                            <form onSubmit={this._handleSubmit}>
                                <InputWrap error={this.state.error === "NAME"} message="*Required field">
                                    <label htmlFor="NAME">Full name</label>
                                    <input
                                        type="text"
                                        onChange={this._handleChange}
                                        name="name"
                                    />
                                </InputWrap>
                            
                                <InputWrap>
                                    <label htmlFor="PHONE">Phone number</label>
                                    <input
                                        type="text"
                                        onChange={this._handleChange}
                                        name="phone"
                                    />
                                </InputWrap>

                                <InputWrap error={this.state.error === "email"} message="*Required field">
                                    <label htmlFor="email">Email</label>
                                    <input
                                        type="email"
                                        onChange={this._handleChange}
                                        name="email"
                                    />
                                </InputWrap>

                                <InputWrap error={this.state.error === "message"} message="*Required field">
                                    <label htmlFor="message">Message</label>
                                    <textarea
                                        name="message"
                                        onChange={this._handleChange}
                                    />
                                </InputWrap>
                                <input className="email-submit-btn" type="submit" value="Submit &rarr;" />
                            </form>
                        </Iff>
                    </Columns>
                </div>
            </div>
        );
    }
}
export default ContactForm;