import React from 'react';
import './style.scss';
import addToMailchimp from 'gatsby-plugin-mailchimp'
import Columns from '../../Columns/Columns';
import Iff from '../../Iff';
import InputWrap from '../InputWrap/InputWrap';

class EmailSignup extends React.Component {
    state = {
        NAME: null,
        PHONE: null,
        email: null,
        error: null,
        error_message: null,
        sent: false,
        _holder: ""
    }

    _handleChange = (e) => {
        this.setState({
            [`${e.target.name}`]: e.target.value,
        });
    }

    _handleSubmit = (e) => {
        e.preventDefault();

        if (!this.state.NAME || this.state.NAME.trim().length < 2) {
            this.setState({error: "NAME"});
            return;
        } else if (!this.state.email || this.state.email.trim().length < 2 || !this.state.email.includes('@')) {
            this.setState({error: "email"});
            return;
        }
        
        let fields = {
            NAME: this.state.NAME,
            "b_3a991e7ceaddc023dcce212d3_edcfe00a3e": this.state._holder
        };
        if (this.props.phone)
            fields["PHONE"] = this.state.PHONE;

        if (typeof this.props.groupid !== "undefined" && this.props.groupid !== "")
            fields["id"] = this.props.groupid;

        addToMailchimp(this.state.email, fields)
            .then(({ msg, result }) => {
                console.log('msg', `${result}: ${msg}`);

                if (result !== 'success') {
                    throw msg;
                }
                this.setState({error: false, sent:true});
            })
            .catch((err) => {
                let message = "Something went wrong when trying to add you to our system.";
                if (err.includes("is already subscribed")) { // Show success message
                    this.setState({error: false, sent:true});
                    return;
                } else if (err.includes("has too many recent signup requests")) {
                    message = "The system is reporting too many recent signup requests on your part – are you a robot?"
                }
                this.setState({error: true, error_message: message});
            });
    }

    render() {
        return (
            <div className="blockwrap">
                <div className="base-styles email-sign-up">
                    <Columns className="content">
                        <Iff condition={this.state.sent}>
                            <div className="success">
                                <h1 className="animate-underline">Thank you for signing up!</h1>
                            </div>
                        </Iff>
                        <Iff condition={!this.state.sent}>
                            <div>
                                <p className="description">{this.props.cta_text}</p>
                                <Iff condition={this.state.error === true}>
                                    <p className="error">{this.state.error_message}</p>
                                </Iff>
                            </div>
                            <form onSubmit={this._handleSubmit}>
                                
                                    <InputWrap error={this.state.error === "NAME"} message="*Required field">
                                        <label htmlFor="NAME">Name</label>
                                        <input
                                            type="text"
                                            onChange={this._handleChange}
                                            name="NAME"
                                        />
                                    </InputWrap>

                                    <InputWrap error={this.state.error === "email"} message="*Required field">
                                        <label htmlFor="email">Email</label>
                                        <input
                                            type="email"
                                            onChange={this._handleChange}
                                            name="email"
                                        />
                                    </InputWrap>

                                    <Iff condition={this.props.phone}>
                                        <InputWrap>
                                            <label htmlFor="PHONE">Phone number (Optional)</label>
                                            <input
                                                type="text"
                                                onChange={this._handleChange}
                                                name="PHONE"
                                            />
                                        </InputWrap>
                                    </Iff>
                                    {/* provided by mailchimp. this is used to stop bots */}
                                    <div style={{'position':'absolute', 'left': '-5000px'}} aria-hidden="true">
                                        <input 
                                            type="text"
                                            name="_holder"
                                            tabIndex="-1"
                                            value=""
                                            onChange={this._handleChange}
                                        />
                                    </div>
                                    <button className="email-submit-btn btn-basic" type="submit" name="submit">Submit <i className="fa fa-long-arrow-right"></i></button>
                            </form>
                        </Iff>
                    </Columns>
                </div>
            </div>
        );
    }
}
export default EmailSignup;