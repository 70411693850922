import React, {useEffect } from 'react';
import './style.scss';


export default (props) => {

  // These functions come from FlipCause 
    function open_window(cause_id,domain_link) {
    //var  protocol=String(document.location.protocol);
    var new_url;
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
  
      //new_url="https://www."+ domain_link +"/widget/"+cause_id
      new_url="https://"+ domain_link +"/widget/"+cause_id    
      window.open(new_url);
    
    } else {
        
        document.getElementById("fc-lightbox-loader").style.display="block";
        document.getElementById("fc-fade").style.display="block";
        document.getElementById("fc-fade").style.webkitAnimation="backfadesin .5s";
        document.getElementById("fc-fade").style.animation="backfadesin .5s";
        document.getElementById("fc-fade").style.mozAnimation="backfadesin .5s";
        document.getElementById("fc-light").style.display="block";
        document.getElementById("fc-light").style.webkitAnimation="fadesin 0s";
        document.getElementById("fc-light").style.animation="fadesin 0s";
        document.getElementById("fc-light").style.mozAnimation="fadesin 0s";
        document.getElementById("fc-main").style.display="block";
        document.getElementById("fc-main").style.webkitAnimation="fadesin 2.2s";
        document.getElementById("fc-main").style.animation="fadesin 2.2s";
        document.getElementById("fc-main").style.mozAnimation="fadesin 2.2s";
        document.getElementById("fc-close").style.display="block";
        document.getElementById("fc-close").style.webkitAnimation="fadesin 2.2s";
        document.getElementById("fc-close").style.animation="fadesin 2.2s";
        document.getElementById("fc-close").style.mozAnimation="fadesin 2.2s";
        document.getElementById("fc-myFrame").style.display="block";
        document.getElementById("fc-myFrame").style.webkitAnimation="fadesin 2.2s";
        document.getElementById("fc-myFrame").style.animation="fadesin 2.2s";
        document.getElementById("fc-myFrame").style.mozAnimation="fadesin 2.2s";
        
        //document.getElementById("fc-myFrame").src="https://www."+ domain_link +"/widget/"+cause_id;
        
        document.getElementById("fc-myFrame").src="https://"+ domain_link +"/widget/"+cause_id;
      }
    }
    
    function close_window() {
      document.getElementById("fc-lightbox-loader").style.display="none";
      document.getElementById("fc-fade").style.display="none";
      document.getElementById("fc-light").style.display="none";
      document.getElementById("fc-main").style.display="none";
      document.getElementById("fc-close").style.display="none";
      document.getElementById("fc-myFrame").style.display="none";
    }
    
    function embed_frame(cause_id,domain_link) {
      document.getElementById("fc-embed-loader").style.display="block";
      document.getElementById("fc-embed-main-box").style.display="block";
      document.getElementById("fc-embed-main-box").style.webkitAnimation="fadesin 2s";
      document.getElementById("fc-embed-main-box").style.animation="fadesin 2s";
      document.getElementById("fc-embed-main-box").style.mozAnimation="fadesin 2s";
      document.getElementById("fc-embed-frame").style.display="block";
      document.getElementById("fc-embed-frame").style.webkitAnimation="fadesin 2s";
      document.getElementById("fc-embed-frame").style.animation="fadesin 2s";
      document.getElementById("fc-embed-frame").style.mozAnimation="fadesin 2s";
  
      //document.getElementById("fc-embed-frame").src="https://www."+ domain_link +"/widget/"+cause_id;
      
      document.getElementById("fc-embed-frame").src="https://"+ domain_link +"/widget/"+cause_id;
    }
    

    useEffect(() => {
      embed_frame(props.campaign, props.domain)
    });

  return ( 
      <div className="blockwrap">
          <div className="base-styles donation-form">
              <div className="DonationsForm" dangerouslySetInnerHTML={{__html: '\
                <div id="fc-fade" class="fc-black_overlay" onclick="close_window()"></div>\
                <div id="fc-light" class="fc-white_content">\
                  <div id="fc-lightbox-loader" class="fc-lightbox-loader" ></div>\
                  <div id="fc-main" class="fc-main-box">\
                    <div id="fc-close" class="fc-widget_close" onclick="close_window()" ></div>\
                    <iframe id="fc-myFrame" iframe height="580" width="925" style="border: 0;border-radius:5px 5px 5px 5px; box-shadow:0 0 8px rgba(0, 0, 0, 0.5);" scrolling="no" src="" ></iframe>\
                  </div>\
                </div>\
                <div>\
                  <div id="fc-embed-loader" class="fc-direct-embed-loader" style="top: 257.5px;" ></div>\
                  <div id="fc-embed-main-box" class="fc-embed-main-box">\
                    <iframe id="fc-embed-frame" style="width:100%; border:none; margin: 0 auto 0 auto;" src="" scrolling="no" ></iframe>\
                  </div>\
                </div>'}} />
          </div>
      </div>
  );
}
